import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { GenericModalComponent } from '@components/generic-modal/generic-modal.component';
import {
  CELLPHONE_LENGTH,
  CHILEAN_CELLPHONE_PATTERN,
  EMAIL_KEY,
  EMAIL_PATTERN,
  LANDING_PRODUCTS,
  RUT_KEY,
} from '@constants/constants';
import { LANDING_CONTENT } from '@constants/landing.constants';
import { Section } from '@interfaces/landing.interface';
import { TranslateService } from '@ngx-translate/core';
import { FontService } from '@providers/font/font.service';
import { LoadingService } from '@providers/loading.service';
import { ContactService } from '@services/contact/contact.service';
import { finalize } from 'rxjs/operators';
import { validateRut } from 'src/app/common/validators/rut.validators';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent implements OnInit {
  @Input() selectedProduct: string;
  public pageContent = LANDING_CONTENT;
  public contactForm: FormGroup;
  public loading = false;
  public products: Array<Section> = LANDING_PRODUCTS;
  public cellphoneLength = CELLPHONE_LENGTH;
  public cellphonePattern = CHILEAN_CELLPHONE_PATTERN;
  public emailPattern = EMAIL_PATTERN;

  constructor(
    public font: FontService,
    private contactService: ContactService,
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private loadingService: LoadingService,
    private readonly translate: TranslateService,
  ) {
    this.contactForm = this.formBuilder.group({
      rut: ['', [Validators.required, validateRut]],
      cellphone: ['', [
        Validators.maxLength(this.cellphoneLength),
        Validators.minLength(this.cellphoneLength),
        Validators.pattern(this.cellphonePattern)
      ]],
      email: ['', [Validators.email, Validators.pattern(this.emailPattern)]],
      product: ['', Validators.required],
    }, { validators: this.atLeastOneContactValue });
  }

  get form() { return this.contactForm.controls; }

  get rut() { return this.form.rut; }

  get cellphone() { return this.form.cellphone; }

  get email() { return this.form.email; }

  get product() { return this.form.product; }

  get messages() {return this.pageContent.sections.contactUs.form.controls; }

  public ngOnInit() {
    this.fillContactForm();
    this.loadingService.hideLoading();
  }

  public getErrorMessage(controlName: string) {
    const control = this.form[controlName];

    if (control.hasError('required') && control.touched) {
      return this.messages.rut.errors.required;
    }
    if (this.invalidRut(controlName)) {
      return this.messages.rut.errors.invalidFormat;
    }
    if (this.invalidEmail(controlName)) {
      return this.messages.email.errors.invalidFormat;
    }
    if (this.invalidCellPhoneFormat(controlName)) {
      return this.messages.cellphone.errors.invalidFormat;
    }
    if (this.invalidCellPhone(controlName)) {
      return this.messages.cellphone.errors.invalidLength;
    }
    return '';
  }

  public send() {
    if (this.contactForm.invalid) { return; }
    this.loadingService.showLoading();
    this.contactService.sendContact(this.contactForm.value)
      .pipe(finalize(() => this.loadingService.hideLoading()))
      .subscribe(() => this.handleContactSuccess(),
        () => this.handleContactError());
  }

  private invalidRut(controlName: string) {
    const control = this.form[controlName];
    return control.invalid && control.dirty && controlName === 'rut';
  }

  private invalidEmail(controlName: string) {
    const control = this.form[controlName];
    return control.hasError('email') || (control.hasError('pattern') && controlName === 'email');
  }

  private invalidCellPhone(controlName: string) {
    const control = this.form[controlName];
    return control.hasError('minlength') || control.hasError('maxlength') ||
      (control.hasError('pattern') && controlName === 'cellphone');
  }

  private invalidCellPhoneFormat(controlName: string) {
    const cellphone = this.form[controlName].value;
    return cellphone && cellphone[0] !== '9' && controlName === 'cellphone';
  }

  private atLeastOneContactValue(form: FormGroup): ValidationErrors {
    if (form.value['email'] || form.value['cellphone']) { return; }
    return { atLeastOneRequired: 'Al menos uno de los campos es obligatorio.' };
  }

  private handleContactSuccess() {
    const { message, messageDescription, button } = this.pageContent.sections.contactUs.form.modal.success;

    const data = {
      message: this.translate.instant(message),
      messageDescription: this.translate.instant(messageDescription),
      primaryButtonText: this.translate.instant(button),
      icon: 'i-success'
    };
    this.openModal(data);
  }

  private handleContactError() {
    const { message, messageDescription, button } = this.pageContent.sections.contactUs.form.modal.success;

    const data = {
      message: this.translate.instant(message),
      messageDescription: this.translate.instant(messageDescription),
      primaryButtonText: this.translate.instant(button),
      icon: 'i-warning',
    };
    this.openModal(data);
  }

  private openModal(data) {
    const dialogRef = this.dialog.open(GenericModalComponent, {
      panelClass: 'alert-modal',
      data
    });
    dialogRef.afterClosed().subscribe(() => this.loading = false);
  }

  private fillContactForm() {
    this.contactForm.controls.rut.setValue(sessionStorage.getItem(RUT_KEY) || '');
    this.contactForm.controls.email.setValue(sessionStorage.getItem(EMAIL_KEY) || '');
    this.contactForm.controls['product'].setValue(this.selectedProduct || this.products[0].name);
  }
}
