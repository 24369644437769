import { UnsubscribeComponent } from 'src/app/unsubscribe/unsubscribe.component';
import { LandingComponent } from './landing/landing.component';
import { LoginComponent } from './login/login.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  VIDEO_ROUTE, SAVINGS_ACCOUNT_ROUTE, APV_ROUTE,
  SECURITY_KEY_ROUTE, ACCESS_KEY_ROUTE, ACCESS_KEY_ROUTE_OLD, UNSUBSCRIBE_ROUTE
} from '@constants/constants';

const routes: Routes = [
  { path: `${VIDEO_ROUTE}`, component: LoginComponent },
  { path: `${VIDEO_ROUTE}/:nameId`, component: LoginComponent },
  { path: `${SAVINGS_ACCOUNT_ROUTE}`, component: LandingComponent },
  { path: `${APV_ROUTE}`, component : LandingComponent },
  { path: `${SECURITY_KEY_ROUTE}`, component: LandingComponent },
  { path: `${ACCESS_KEY_ROUTE}`, component: LandingComponent },
  { path: `${ACCESS_KEY_ROUTE_OLD}`, component: LandingComponent },
  { path: `${UNSUBSCRIBE_ROUTE}/:nameId`, component: UnsubscribeComponent },
  { path: `:lang/${VIDEO_ROUTE}`, component: LoginComponent },
  { path: `:lang/${VIDEO_ROUTE}/:nameId`, component: LoginComponent },
  { path: `:lang/${SAVINGS_ACCOUNT_ROUTE}`, component: LandingComponent },
  { path: `:lang/${APV_ROUTE}`, component : LandingComponent },
  { path: `:lang/${SECURITY_KEY_ROUTE}`, component: LandingComponent },
  { path: `:lang/${ACCESS_KEY_ROUTE}`, component: LandingComponent },
  { path: `:lang/${UNSUBSCRIBE_ROUTE}/:nameId`, component: UnsubscribeComponent },
  { path: '**', redirectTo: `${VIDEO_ROUTE}` },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
