import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatDialogModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatRadioModule,
  MatSelectModule,
  MatSidenavModule
} from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BannerComponent } from '@components/banner/banner.component';
import { CardComponent } from '@components/card/card.component';
import { ContactFormComponent } from '@components/contact-form/contact-form.component';
import { FooterComponent } from '@components/footer/footer.component';
import { GenericModalComponent } from '@components/generic-modal/generic-modal.component';
import { HeaderDesktopComponent } from '@components/header-desktop/header-desktop.component';
import { HeaderMobileComponent } from '@components/header-mobile/header-mobile.component';
import { HeaderComponent } from '@components/header/header.component';
import { IndiVideoComponent } from '@components/indi-video/indi-video.component';
import { InfoBannerComponent } from '@components/info-banner/info-banner.component';
import { LoadingComponent } from '@components/loading/loading.component';
import { SuperintendenceComponent } from '@components/superintendence/superintendence.component';
import { TabNavigationComponent } from '@components/tab-navigation/tab-navigation.component';
import { VideoComponent } from '@components/video/video.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LoadingService } from '@providers/loading.service';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DirectivesModule } from './directives/directives.module';
import { RecaptchaInterceptor } from './interceptors/recaptcha.interceptor';
import { LandingComponent } from './landing/landing.component';
import { LoginComponent } from './login/login.component';
import { UnsubscribeComponent } from './unsubscribe/unsubscribe.component';
import { Util } from './util/util';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LoadingComponent,
    GenericModalComponent,
    FooterComponent,
    VideoComponent,
    IndiVideoComponent,
    LandingComponent,
    HeaderComponent,
    HeaderDesktopComponent,
    HeaderMobileComponent,
    ContactFormComponent,
    TabNavigationComponent,
    CardComponent,
    BannerComponent,
    InfoBannerComponent,
    SuperintendenceComponent,
    UnsubscribeComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatDialogModule,
    MatIconModule,
    MatSidenavModule,
    DirectivesModule,
    MatRadioModule,
    RecaptchaV3Module,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    LoadingService,
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.RECAPTCHA_SITE_KEY
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RecaptchaInterceptor,
      multi: true
    },
    Util
  ],
  entryComponents: [
    GenericModalComponent,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
