import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { LoadingService } from './providers/loading.service';
import { Router } from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import { SAVINGS_ACCOUNT_ROUTE, APV_ROUTE
  , SECURITY_KEY_ROUTE, ACCESS_KEY_ROUTE
  , LANGUAGES_ARRAY, LANGUAGE_KEY, DEFAULT_LANG } from '@constants/constants';

import { version } from '../../package.json';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public showLoading = true;
  public VERSION = version;
  private selectedLang: string;

  constructor(
    private loadingService: LoadingService,
    private router: Router,
    private location: Location,
    public translate: TranslateService,
  ) {
    this.initializeLangs(LANGUAGES_ARRAY);
    this.onChangeLang();

    this.changeLang(this.location.path());

    this.loadingService.isLoading.subscribe((isLoading: boolean) => {
      setTimeout(() => {
        this.showLoading = isLoading;
      }, 500);
    });
  }

  // Public methods

  public initializeLangs(langsArray: string[]) {
    this.translate.addLangs(langsArray);
    this.requestLang();
  }

  // Private methods

  private requestLang() {
    const lang = sessionStorage.getItem(LANGUAGE_KEY);
    this.selectedLang = lang || DEFAULT_LANG;
    if (!lang) {this.translate.use(this.selectedLang); }
  }

  private onChangeLang() {
    this.translate.onLangChange.subscribe((params: any) => {
      sessionStorage.setItem(LANGUAGE_KEY, params.lang);
      this.selectedLang = params.lang;
    });
  }

  private changeLang(path: string) {
    const pathArray = path.split('/');
    if (LANGUAGES_ARRAY.includes(pathArray[1])) {
      this.translate.use(pathArray[1]);
      this.location.go(pathArray.join('/'));
    } else {
      pathArray[1] = this.selectedLang || DEFAULT_LANG;
      this.translate.use(pathArray[1]);
    }
  }

  get showHeader(): boolean {
    return !(this.router.url.includes(SAVINGS_ACCOUNT_ROUTE) || this.router.url.includes(APV_ROUTE)
      || this.router.url.includes(SECURITY_KEY_ROUTE) || this.router.url.includes(ACCESS_KEY_ROUTE));
  }
}

