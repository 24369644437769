import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { GenericModalComponent } from '@components/generic-modal/generic-modal.component';
import { UNSUBSCRIBE_CONTENT, UNSUBSCRIBE_HEADER } from '@constants/unsubscribe.constants';
import { Section } from '@interfaces/landing.interface';
import { TranslateService } from '@ngx-translate/core';
import { FontService } from '@providers/font/font.service';
import { LoadingService } from '@providers/loading.service';
import { SubscriptionService } from '@services/subscription/subscription.service';

interface CheckSubscriptionResponse {
  isSubscribed: boolean;
}

@Component({
  selector: 'app-unsubscribe',
  templateUrl: './unsubscribe.component.html',
  styleUrls: ['./unsubscribe.component.scss']
})
export class UnsubscribeComponent implements OnInit {
  public pageContent = UNSUBSCRIBE_CONTENT;
  public nav: Array<Section> = [UNSUBSCRIBE_HEADER];
  public header: Section = UNSUBSCRIBE_HEADER;
  public unsubscribeForm: FormGroup;
  public isSubscribed = true;
  public loading = true;
  public previously = false;
  public activeSection = this.header[0];
  public entityNameId: string;

  constructor(
    public font: FontService,
    private formBuilder: FormBuilder,
    private loadingService: LoadingService,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private subscriptionService: SubscriptionService,
    private dialog: MatDialog,
  ) {
    this.entityNameId = this.route.snapshot.params.nameId;
    this.checkSubscription();
    this.unsubscribeForm = this.formBuilder.group({
      mail: ['', [Validators.required]],
    });
  }

  public unsubscribe() {
    const { mail } = this.unsubscribeForm.controls;
    this.loadingService.showLoading();
    this.subscriptionService.unsubscribe(mail.value, this.entityNameId).toPromise()
      .then(() => {
        this.isSubscribed = false;
      })
      .catch(() => {
        this.handleError();
      })
      .finally(() => {
        this.loadingService.hideLoading();
      });
  }

  public checkSubscription() {
    this.subscriptionService.checkSubscription(this.entityNameId).toPromise()
      .then((response: CheckSubscriptionResponse) => {
        if (!response.isSubscribed) {
          this.isSubscribed = false;
          this.previously = true;
        }
      })
      .catch(() => {
      })
      .finally(() => {
        this.loading = false;
        this.loadingService.hideLoading();
      });
  }

  public ngOnInit() {
    this.activeSection = this.nav[0];
  }

  public goToMyVideo() {
    this.router.navigateByUrl(`${ this.translate.currentLang }/video/${ this.entityNameId }`);
  }

  private handleError() {
    const data = {
      code: 40002,
      message: this.pageContent.errors.checkEmail
    };
    this.dialog.open(GenericModalComponent, {
      panelClass: 'alert-modal',
      data: {
        ...data,
        primaryButtonText: this.pageContent.errors.buttons.return,
        icon: 'i-warning',
      }
    });
  }
}
